import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const MiDiamondEngRing = () => {
  return (
    <div className='mi_DiamondEngRing_section'>
      <Container fluid>
        <Row>
          <Col sm={12} md={6} lg={6} className='pl-md-0'>
            <div className='mi_diamond_box'>
            <h2 class="mi_heading">Start with a Diamond </h2>
            <p>Select a diamond and choose a setting to create your own diamond engagement ring.</p>
              <NavLink to="/diamonds" className="mi_btn_outline">Shop Now</NavLink>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} className='pr-md-0'>
            <div className='mi_diamond_box eng_banner'>
            <h2 class="mi_heading">Engagement Rings </h2>
            <p>Select your perfect Engagement Ring with diamond. Choose from wide range of designer rings.</p>
              <NavLink to="/ringsettings" className="mi_btn_outline">Shop Now</NavLink>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MiDiamondEngRing
