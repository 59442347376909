import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button, Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import LazyLoad from 'react-lazy-load';
import bannerImg from '../../../Assets/images/miami-imgs/home-banner.png'
import bannerImgMobile from '../../../Assets/images/miami-imgs/home-banner-mobile.png'


const BannerSlider = () => {
  const history = useHistory();
  const [banner,setBanner] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
 //-----------------------------------Banner
 setLoading(true)
 axios.get(base_url + `/home/slider`, {
  headers: postHeader
})
  .then(response => {
    if (response.data.status == 1) {
      setLoading(false)
      setBanner(response.data.data);
      localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  })
  .catch((error) => {
    console.log(error);
  });
  },[])
  return (
    <>
    
      {/* {banner?.map((val, index) => */}
        {/* <LazyLoad height={552} width={1910}> */}
        {/* {!loading?  */}
         <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? bannerImgMobile : bannerImg})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col sm={12} md={{span:10, offset:1}} className='m-auto'>
                  <div className={isMobile ? 'text-center' :'text-left'}>
                    <h1 data-aos="fade-up" data-aos-duration="700">Engagement Rings</h1>
                    <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">The Perfect Ring for Your Perfect Day</p>
                    <div className='d-sm-block d-md-flex justify-content-left'>
                    <Button className={isMobile ? 'w-auto ml-4 mr-4' :'m-0'} data-aos="fade-up" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/jewelry/wedding-rings') }} variant="outline-dark">EXPLORE NOW</Button>
                    {/* <Button data-aos="fade-up" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/jewelry/fine-jewelry') }} variant="outline-dark">Fine jewellery</Button> */}
                    </div>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* :<Skeleton variant="text" animation="wave"/> */}
        {/* } */}
    {/* </LazyLoad>  */}
    {/* //  )} */}
      
    </>
  )
}

export default BannerSlider;