import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Banner = ({ bannerImg, title, nestedBreadCrumb, mainEducation }) => {
  return (
    <>
      <div className='banner' style={{background: `url(${bannerImg}) no-repeat center/cover`}}>
          <h1 className='text-center m-0'>{title}</h1>
          <Breadcrumb className='edu-breadcrumb'>
              <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
              {nestedBreadCrumb && <BreadcrumbItem><Link to="/education">Education</Link></BreadcrumbItem>}
              <BreadcrumbItem active>{title}</BreadcrumbItem>
          </Breadcrumb>

        {mainEducation && <h4>Diamond education is the understanding of the 4C's of diamonds (colour, cut, carat, clarity) and the factors that impact diamond quality and price.</h4>}
      </div>
    </>
  )
}

export default Banner;