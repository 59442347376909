import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const MiWeddingRingFineJewelry = () => {
  return (
    <>
      <div className='mi_DiamondEngRing_section mt-4'>
<Container fluid>
    <Row>
      <Col sm={12} md={6} lg={6} className='pl-md-0'>
        <div className='mi_diamond_box wedding_Ring_banner'>
        <h2 class="mi_heading">Wedding Rings </h2>
        <p>Find the perfect symbol of your love. Discover eternity diamond rings, classic bands, and unique styles.</p>
          <NavLink to="/jewelry/wedding-rings" className="mi_btn_outline">Shop Now</NavLink>
        </div>
      </Col>
      <Col sm={12} md={6} lg={6} className='pr-md-0'>
        <div className='mi_diamond_box fine_banner'>
        <h2 class="mi_heading">Fine Jewellery </h2>
        <p>Perfect for any occasion, our stunning diamond and gemstone fine jewellery collection is unique and breathtaking.</p>
          <NavLink to="/jewelry/fine-jewelry" className="mi_btn_outline">Shop Now</NavLink>
        </div>
      </Col>
    </Row>
</Container>
      </div>
    </>
  )
}

export default MiWeddingRingFineJewelry
