import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import aboutImg from "../../Assets/images/miami-imgs/about-home.png";
import { client_name } from '../../Helpers/request';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const AboutSection = () => {
    return (
        <section className="rcs_about_section my-5 pb-5">
            <Container fluid>
                <Row className='align-items-center'>
                    <Col md={7} xs={12} >
                        <div className="rcs_about_img">
                            <img src={aboutImg} alt="about-img" title="about-img"/>
                        </div>
                    </Col>
                    <Col md={5} xs={12}>
                        <div className="rcs_about_text" >
                            <h1 className='text-uppercase'>About us</h1>
                            <p>Welcome to the world of The Diamonds Collection. Diamonds have become a symbol of eternity present in all aspects of our life, therefore we believe that diamonds can be worn and should be worn at the time your heart desires, not just special occasions. Diamonds symbolise love, friendship, connection, commitment and luxury. We therefore endeavour to provide you with a choice of diamond set jewellery, appropriate to mark that given time in your life.</p>
                            <Link className="mi_btn_outline" to="/our-story" variant="outline-dark"> READ MORE </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default AboutSection;
