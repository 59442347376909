import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import AOS from 'aos';
import 'aos/dist/aos.css' ;
import { useSelector } from 'react-redux';
import { client_name } from "../../../Helpers/request";
import MiShopDiamondsByShap from "./MiShopDiamondsByShap";
import MiDiamondEngRing from "./MiDiamondEngRing";
import MiWeddingRingFineJewelry from "./MiWeddingRingFineJewelry";
import MiCustomer from "./MiCustomer";
import SignupTo from "./SignupTo";
import AboutSection from "../AboutSection";
import Insta from "./Insta";

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  useEffect(() => {
    AOS.init({
      duration : 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content={client_name.c_name} />
        <link rel="canonical" href="https://hemmingjewelers.com/" />
      </Helmet>
      <BannerSlider />
      <MiShopDiamondsByShap/>
      <MiDiamondEngRing/>
      {/* <MiBestSeller/> */}
      <MiWeddingRingFineJewelry/>
      {/* <MiCustomDesign/> */}
      {/* <MiClintSlider/> */}
      <AboutSection />
      <MiCustomer/>
      {/* <RingStyle /> */}
      {/* <ExploreCollection /> */}
      {/* <Ringbuilderhome /> */}
      {/* <ShopDiamondShape /> */}
      {/* <Gemstones /> */}
      {/* <Virtualappointment/> */}
      {/* <Customer /> */}
      <Insta />
      {/* <MiCollectionAccordian/> */}
      <SignupTo />

      {/* <Colordiamond /> */}
      {/* <Letestcreation/> */}
      {/* <ServiceSection /> */}
      {/* <SignupTo /> */}
      {/* <Newsletter /> */}
      {/* <Featured /> */}
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      {/* <Collection1 /> */}
      {/* <StateJewelry /> */}
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      {/* <Collection2 /> */}
      {/* <IndianJewelry /> */}
      {/* <Philanthropy /> */}
      {/* <Testimonial /> */}
      {/* <Insta /> */}
      {/* <Newsletter /> */}
    </>
  )
}

export default Home;