import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Arrowleft from '../../../Assets/images/miami-imgs/arrow-left.png'
import ArrowRight from '../../../Assets/images/miami-imgs/arrow-right.png'
import Slider from 'react-slick'
import { BsQuote } from "react-icons/bs";
import Rating from '@mui/material/Rating';

const MiCustomer = () => {
    const customerData = [
        {
            
           cName:"John Smith",
           cText:"Effortless online purchase. Varied styles, easy customization, fair pricing. Swift shipping, secure packaging. Loved one delighted with the ring's quality. Overall, highly satisfied with the buying process." ,
           cUrl:"#",
        },
        {
          
           cName:"John Smith",
           cText:"Effortless online purchase. Varied styles, easy customization, fair pricing. Swift shipping, secure packaging. Loved one delighted with the ring's quality. Overall, highly satisfied with the buying process." ,
           cUrl:"#",
        },
        {
           cName:"John Smith",
           cText:"Effortless online purchase. Varied styles, easy customization, fair pricing. Swift shipping, secure packaging. Loved one delighted with the ring's quality. Overall, highly satisfied with the buying process. " ,
           cUrl:"#",
        },
        {
           cName:"John Smith",
           cText:"Effortless online purchase. Varied styles, easy customization, fair pricing. Swift shipping, secure packaging. Loved one delighted with the ring's quality. Overall, highly satisfied with the buying process." ,
           cUrl:"#",
        },
      ]
    const settingsCustomer = {
        dots: true,
        infinite: true,
        autoplay: false,
        arrows:true,
        prevArrow:<img src={Arrowleft}/>,
        nextArrow:<img src={ArrowRight}/>,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <>
         <div className='mi_customer_section'>
            <Container fluid>
                <Row>
                <Col sm={12} md={12} lg={12}>
            <div className='custome_heading_box text-center'>
<h2 className='mi_heading'>What People Say</h2>
{/* <p className='text-center'>Create a beautiful, one-of-a-kind representation of your unique love. </p> */}
            </div>
        </Col>
                </Row>
                <Row>
                <Col sm={12} md={12} lg={12} className='p-0'>
                  <Slider {...settingsCustomer} className='mi_customer_slider'>
                        {customerData.map((val)=>(
                          <div className='mi_customer_slider_img_box_outer'>
                           <div className='mi_customer_slider_img_box'>
                             {/* <Image src={val.bsImg} className="mi_client_slider_img" alt={ "customer Image"}/> */}
                             <BsQuote  className='mi_cutomer_qutoe'/>
                             <p>{val.cText} </p>
                             <div className='mb-3'>
                                 <a href='#' target='_blank' className='Read_more'>Read More</a>
                             </div>
                             {/* <a href='#' target='_blank' className='Read_more'>Read More</a><br></br> */}
                             <Rating name="half-rating-read" defaultValue={5} precision={5} readOnly  className='mi_cutomer_rating'/>
                             <p className='name'>{val.cName}</p>
                        </div>
                          </div>
                          
                        ))}
                        </Slider>
                </Col>
                </Row>
            
            </Container>
         </div>
    </>
  )
   }

export default MiCustomer
