import { createSlice } from "@reduxjs/toolkit";


export const wishSlice = createSlice({
    name:'wishlist',
    initialState:{
        whishlistlength:0
    },
    reducers:{
        whishlistlength: (state,action) => {
         state.whishlistlength = action.payload
        }
    }
})

export const { whishlistlength} = wishSlice.actions
export default wishSlice.reducer