import React from 'react'
import { CircularProgress } from '@material-ui/core';

export default function Loader({className}) {
    return (
        <div className={`rcs_filter_wrapper mx-auto pt-3 pb-3 ${className}`} >
            <div className='loaderCustom'>

            </div>
            {/* <CircularProgress className="rcs_filter_loader" /> */}
        </div>
    )
}
