import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import "../../Assets/css/home.css";

const Insta = () => {
    return (
        <>
            <Container>
                <Row>
                    <div className="rcs_news_content1 w-100 mt-5 mb-3" >
                        <h2 className='mi_heading text-center font-weight-normal'>View our Instagram</h2>
                    </div>
                </Row>
            </Container>
            <iframe src="https://instagram.demobw.live/thediamondcollection/" frameborder="0" width="100%" class="insta-iframe"></iframe>
        </>
    )
}

export default Insta;