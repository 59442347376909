import React from 'react'
import './css/miami.css'
// import RoundShape from "../../Assets/images/diamond-shape/Diamond_Round.svg";
// import princess from "../../Assets/images/diamond-shape/princess.svg";
// import cushion from "../../Assets/images/diamond-shape/cushion.svg";
// import emerald from "../../Assets/images/diamond-shape/emerald.svg";
// import oval from "../../Assets/images/diamond-shape/oval.svg";
// import radiant from "../../Assets/images/diamond-shape/radiant.svg";
// import asscher from "../../Assets/images/diamond-shape/asscher.svg";
// import marquise from "../../Assets/images/diamond-shape/marquise.svg";
// import heart from "../../Assets/images/diamond-shape/heart.svg";
// import pear from "../../Assets/images/diamond-shape/pear.svg";

import roundImg from "../../../Assets/images/home/diamond-shapes-img/Round.webp";
import princessImg from "../../../Assets/images/home/diamond-shapes-img/Princess.webp";
import cushionImg from "../../../Assets/images/home/diamond-shapes-img/Cushion.webp";
import emeraldImg from "../../../Assets/images/home/diamond-shapes-img/Emerald.webp";
import ovalImg from "../../../Assets/images/home/diamond-shapes-img/Oval.webp";
import radiantImg from "../../../Assets/images/home/diamond-shapes-img/Radiant.webp";
import asscherImg from "../../../Assets/images/home/diamond-shapes-img/Asscher.webp";
import marquiseImg from "../../../Assets/images/home/diamond-shapes-img/Marquise.webp";
import heartImg from "../../../Assets/images/home/diamond-shapes-img/Heart.webp";
import pearImg from "../../../Assets/images/home/diamond-shapes-img/Pear.webp";

import Slider from "react-slick";
import { Col, Container, Image, Row } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useHistory } from 'react-router-dom';
import { isMobile, isMobileOnly } from 'react-device-detect';

const MiShopDiamondsByShap = () => {
    const history = useHistory()

    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    const shopbyshape = (value) =>{
        var data1 = {
            shape: value,
        } 
        sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
        history.push('/diamonds');
        window.scrollTo(0, 0);
        }

        
    const shopbyshape2 = (value) =>{
      var data1 = {
          shape: value,
      } 
      sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
      history.push('/diamonds/lab');
      window.scrollTo(0, 0);
      }
      
const shapData = [
  {
    shapName:"Round",
    shapImg: roundImg,
    shapUrl: "Round",
  },
  {
    shapName:"Princess",
    shapImg: princessImg,
    shapUrl: "Princess",
  },
  {
    shapName:"Cushion",
    shapImg: cushionImg,
    shapUrl: "Cushion",
  },
  {
    shapName:"Emerald",
    shapImg: emeraldImg,
    shapUrl: "Emerald",
  },
  {
    shapName:"Oval",
    shapImg: ovalImg,
    shapUrl: "Oval",
  },
  {
    shapName:"Radiant",
    shapImg: radiantImg,
    shapUrl: "Radiant",
  },
  {
    shapName:"Asscher",
    shapImg: asscherImg,
    shapUrl: "Asscher",
  }, {
    shapName:"Marquise",
    shapImg: marquiseImg,
    shapUrl: "Marquise",
  },
  {
    shapName:"heart",
    shapImg: heartImg,
    shapUrl: "Heart",
  },
  {
    shapName:"Pear",
    shapImg: pearImg,
    shapUrl: "Pear",
  },
]
const shapSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
  ]
};


  return (
    <div className='mi_shop_diamonds_by_shap_section'>
      <Container fluid>
            <Row>
            <Col sm={12} md={12} lg={12}>
                <div className='mi_main_heading_shop_Daimonds_by_Shap'>
                        <h2>Shop Diamonds By Shape</h2>
                </div>
            </Col>
                <Col sm={12} md={12} lg={12}>
                <TabContext value={value} className="mi_tab_outerrr">
                    <TabList onChange={handleChange} aria-label="lab API tabs example" className='mi_shap_tabs_list'>
                        <Tab label="Earth Mined" value="1" className='border-right border-dark my-2 mi_shap_tabs' /> <vr></vr>
                        <Tab label="Lab Grown" value="2" className='mi_shap_tabs'/>
                    </TabList>
                    <TabPanel value= "1">
                    {isMobile ?
                     <div className='mi_shap_mobile_view'>
                      <Slider {...shapSettings}>
                        {shapData.map((val) => 
                           <div>
                              <div className='mi_shap_icon_text' onClick={value === "1" ? () => shopbyshape(val.shapUrl) : () => shopbyshape2(val.shapUrl)}>
                                <Image src={val.shapImg} className='mi_shap_img' alt={val.shapName + 'shap Image' }></Image>
                                <p>{val.shapName}</p>
                              </div>
                              </div>
                           )
                           }  
                       </Slider>
                      </div> 
                      :
                    <div className='mi_shap_row'>
                           <ul>
                           {shapData.map((val) => 
                            <li>
                              <div className='mi_shap_icon_text' onClick={value === "1" ? () => shopbyshape(val.shapUrl) : () => shopbyshape2(val.shapUrl)}>
                                <Image src={val.shapImg} className='mi_shap_img' alt={val.shapName + 'shap Image' }></Image>
                                <p>{val.shapName}</p>
                              </div>
                            </li>
                           )
                           } 
                           </ul>
                      </div>
                     }
                    </TabPanel>
                    <TabPanel value="2">
                    {isMobile ?
                     <div className='mi_shap_mobile_view'>
                      <Slider {...shapSettings}>
                        {shapData.map((val) => 
                           <div>
                              <div className='mi_shap_icon_text' onClick={value === "1" ? () => shopbyshape(val.shapUrl) : () => shopbyshape2(val.shapUrl)}>
                                <Image src={val.shapImg} className='mi_shap_img' alt={val.shapName + 'shap Image' }></Image>
                                <p>{val.shapName}</p>
                              </div>
                              </div>
                           )
                           }  
                       </Slider>
                      </div> 
                      :
                    <div className='mi_shap_row'>
                           <ul>
                           {shapData.map((val) => 
                            <li>
                              <div className='mi_shap_icon_text' onClick={value === "1" ? () => shopbyshape(val.shapUrl) : () => shopbyshape2(val.shapUrl)}>
                                <Image src={val.shapImg} className='mi_shap_img' alt={val.shapName + 'shap Image' }></Image>
                                <p>{val.shapName}</p>
                              </div>
                            </li>
                           )
                           } 
                           </ul>
                      </div>
                     }
                    </TabPanel>
                </TabContext>
                </Col>
            </Row>
      </Container>
    </div>
  )
}

export default MiShopDiamondsByShap
