import { createSlice } from "@reduxjs/toolkit";


export const cartSlice = createSlice({
    name:'cart',
    initialState:{
        cartlength:0
    },
    reducers:{
        cartlengthvalue: (state,action) => {
         state.cartlength = action.payload
        }
    }
})

export const { cartlengthvalue } = cartSlice.actions
export default cartSlice.reducer