import { createSlice } from "@reduxjs/toolkit";

export const metaSlice = createSlice({
    name:'meta',
    initialState:{
        meta:[]
    },
    reducers:{
        metaDetails: (state,action) => {
            state.meta = action.payload
        }
    }

})

export const {metaDetails} = metaSlice.actions
export default metaSlice.reducer